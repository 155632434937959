///
/// Forty by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Contact */

#contact {
	border-bottom: solid 1px _palette(border);
	overflow-x: hidden;

	> .inner {
		padding: 0 !important;

		> .split {
			padding: 0;

			> * {
				@include padding(3em, 0, (0, 0, 0, 3em));
				position: relative;

				&:before {
					border-top: solid 1px _palette(border);
					content: '';
					display: block;
					margin-left: -3em;
					position: absolute;
					top: 0;
					width: calc(100vw + 3em);
				}
			}

			> :first-child {
				&:before {
					display: none;
				}
			}
		}
	}

	@include breakpoint(medium) {
		> .inner {
			> .split {
				> * {
					@include padding(3em, 0, (0, 0, 0, 0));
				}

				> :first-child {
					&:before {
						display: block;
					}
				}
			}
		}
	}

	@include breakpoint(small) {
		> .inner {
			> .split {
				> * {
					@include padding(3em, 0, (0, 0, 0, 0));
				}
			}
		}
	}
}