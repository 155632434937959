///
/// Forty by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Main */

	#main {
		background-color: _palette(bg-alt);

		> * {
			border-top: solid 1px _palette(border);

			&:first-child {
				border-top: 0;
			}

			@include inner;
		}

		&.alt {
			background-color: transparent;
			border-bottom: solid 1px _palette(border);
		}
	}
.row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 20px;
}

.col-6 {
	flex: 0 0 50%;
	max-width: 50%;
	padding: 0 35px;
}

.contact-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.contact-info-nav {
	white-space: nowrap;
}

.contact-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.map-container {
	position: relative;
	padding-bottom: 34%; /* Adjust the percentage to make the map less tall */
	height: 0;
	overflow: hidden;
}

.map-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

@media (max-width: 767px) {
	.col-6 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}

.actions.fit.small {
	max-width: 300px;
	margin: 0 auto 1em auto;
}

@keyframes fadeOutRight {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(100%);
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		transform: translateX(100%);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.fade-out-right {
	animation: fadeOutRight 0.5s forwards;
}

.fade-in-right {
	animation: fadeInRight 0.5s forwards;
}

#service-details {
	padding: 0 20px;
	position: relative;
}

.radiant-text {
	position: relative;
	display: inline-block;

	.lens-flare {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 0;
		height: 0;
		background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 70%);
		border-radius: 50%;
		pointer-events: none;
		opacity: 0;
		z-index: -1;
		animation: lensFlare 2s ease-out forwards;
	}
}

@keyframes lensFlare {
	0% {
		opacity: 0;
		width: 0;
		height: 0;
	}
	50% {
		opacity: 1;
		width: 200px;
		height: 200px;
	}
	100% {
		opacity: 0;
		width: 400px;
		height: 400px;
	}
}
.button.disabled {
	pointer-events: none;
	opacity: 0.6;
	cursor: not-allowed;
}